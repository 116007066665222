import React from "react"
import { css } from "@emotion/core"

import { BREAKPOINTS } from "../css-vars";
import { pixelsToRem, createSpacingUnitMediaQueries } from "./../utils/css-helpers";

const ResponsiveContainer = ({
  children,
  width,
  spacingTop = `full`,
  spacingBottom = `full`,
  tag = `div`,
  ...props
}) => {
  const SPACING_MAP = {
    none: 0,
    half: 0.5,
    full: 1,
  };

  const maxWidth = width === `thin` ? 1040 : BREAKPOINTS.large;

  const ResponsiveContainerTag = tag;

  return (
    <ResponsiveContainerTag {...props} css={css`
      ${createSpacingUnitMediaQueries({
        'margin-bottom': `calc(SPACING_UNIT * ${SPACING_MAP[spacingBottom]})`,
        'margin-top': `calc(SPACING_UNIT * ${SPACING_MAP[spacingTop]})`,
        'width': `calc(100% - SPACING_UNIT)`,
        'max-width': `calc(${pixelsToRem(maxWidth)} - SPACING_UNIT)`,
      })}

      margin-left: auto;
      margin-right: auto;
    `}>
      {children}
    </ResponsiveContainerTag>
  )
};

export default ResponsiveContainer;
