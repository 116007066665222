import { css } from "@emotion/core";
import React from "react";

import { ReactComponent as CtaArrowSvgInline } from "./assets/arrow.svg";
import { responsiveFontSizes, pixelsToRem, mediaQuery } from '../../utils/css-helpers.js';

import { COLORS, DEFAULT_FONT_SIZES_MAP, TRANSITIONS, BREAKPOINTS } from "../../css-vars";

import SmartLink from "../SmartLink";

export const hoverTransform = `0.667em`;

const CallToAction = ({
  link,
  caption = `Learn more`,
  level = `regular`,
  align = `left`,
  spacingTop = `full`,
  spacingBottom = `full`,
  arrow = true,
  title,
  anchorClassName,
  ...props
}) => {

  const FONT_SIZES_MAP = {
    small: DEFAULT_FONT_SIZES_MAP.regular,
    regular: DEFAULT_FONT_SIZES_MAP.regular,
  };

  const [
    fontSizeSmall,
    fontSizeLarge,
  ] = FONT_SIZES_MAP[level];

  const SPACING_MAP = {
    none: 0,
    half: 0.5,
    full: 1,
  };

  // WHY: These will be used in `em`s need to be transformed at different breakpoints
  const PADDING_MAP = {
    small: 0.667,
    regular: 1.2,
  };

  const ctaProps = {
    link,
    title,
  };

  return (
    <div {...props} css={css`
      font-size: 1.13em;
      line-height: 1.7;
      text-align: ${align};
    `}>
      <SmartLink {...ctaProps} className={anchorClassName} css={css`
        ${responsiveFontSizes(fontSizeSmall, fontSizeLarge)}

        display: inline-flex;
        align-items: center;
        margin-top: calc(1em * ${SPACING_MAP[spacingTop]});
        margin-bottom: calc(1em * ${SPACING_MAP[spacingBottom]});
        padding: ${PADDING_MAP[level] * 0.66}em ${PADDING_MAP[level] * 1.75 * 0.66}em;
        color: white;
        line-height: 1.35;
        font-weight: 400;
        overflow: hidden;
        position: relative;
        text-align: left;

        ${arrow && `
          padding-right: calc(${hoverTransform} + ${PADDING_MAP[level] * 1.75 * 0.66}em);
        `}

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          padding: ${PADDING_MAP[level]}em ${PADDING_MAP[level] * 1.75}em;

          ${arrow && `
            padding-right: calc(${hoverTransform} + ${PADDING_MAP[level] * 1.75}em);
          `}
        }

        ::before {
          background: ${COLORS.secondaryA11y};
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          transition: box-shadow ${TRANSITIONS.regular}, transform ${TRANSITIONS.regular}, background ${TRANSITIONS.regular};
          width: 100%;
          ${arrow && `
            transform: translateX(-${hoverTransform});
          `}
        }

        :hover,
        :focus,
        :active {

          ::before {
            background: ${COLORS.secondaryA11yDark};
            transform: none;
          }

          svg {
            transform: translateX(calc(${hoverTransform} / 2));
          }
        }

        :active {

          ::before {
            background: ${COLORS.secondaryA11yDarker};
          }
        }

        :focus:not(:active) {

          ::before {
            box-shadow: ${COLORS.primary} 0 0 ${pixelsToRem(5)};
          }
        }
      `}>
        <span css={css`
          margin-right: ${arrow ? `${(1.667 * PADDING_MAP[level]).toFixed(3)}em` : null};
          position: relative;
        `}>
          {caption}
        </span>
        {arrow && <CtaArrowSvgInline css={css`
          flex-shrink: 0;
          // WHY: Sizes come from the SVG
          width: ${(29 / fontSizeLarge).toFixed(3)}em;
          height: ${(18 / fontSizeLarge).toFixed(3)}em;
          position: relative;
          transition: transform ${TRANSITIONS.regular};
        `}/>}
      </SmartLink>
    </div>
  )
};

export default CallToAction;
