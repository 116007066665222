import React from "react";
import Helmet from "react-helmet";

import { graphql, useStaticQuery } from 'gatsby'

const SEO = ({
  title,
  description,
  image,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          deployUrl
        }
      }
    }
  `);

  if (!image) {
    image = `${site.siteMetadata.deployUrl}/social.png`;
  }

  return <Helmet
    htmlAttributes={{ lang: `en` }}
    title={title}
    meta={[
      {
        name: `description`,
        content: description,
      },

      // Open Graph tags
      // title, type, image and url are mandatory (https://ogp.me/#metadata)
      // og:url unfortunately is very hard to obtain here (Gatsby does not provide a simple way
      // to obtain the current page url), so it's skipped, but it's correctly inferred by
      // Facebook anyway (tested on https://developers.facebook.com/tools/debug/).
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:image`,
        content: image,
      },
      {
        property: `og:type`,
        content: `website`,
      },

      // Twitter tags
      // twitter:title and twitter:description are inferred from the respective Open Graph tags:
      // https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started#twitter-cards-and-open-graph
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: image,
      },
      {
        name: `twitter:site`,
        content: `@svclnk`,
      },

      // Old ServiceLink website had this meta on all the pages, so here the
      // behaviour is maintained for compatibility.
      // TODO: configure the Google Search Console to look for a verification
      // file instead, that can be placed in the /static folder, so doing making
      // this meta no longer necessary and lightening both this component and
      // the rendered pages.
      {
        name: `google-site-verification`,
        content: `KqOdLT2ypcj0VhEHJjmhSjxgA_DHEQz-hyRTgPsHxtQ`,
      },
    ]}
  />
};

export default SEO;
