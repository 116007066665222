import React from "react"
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"

import "typeface-roboto";

import { BRANDS } from "../css-vars";
import { createHeroPadding } from "./../utils/css-helpers";
import Header from "./Header/"
import Footer from "./Footer"
import TopBackground from "./TopBackground";
import GlobalCss from "./GlobalCss";

const Layout = ({
  brand = BRANDS.servicelink,
  hero,
  showContactForm = true,
  children
}) => {

  return (
    <>
      <GlobalCss brand={brand}/>
      <Helmet>
        <script>{`
          var ua = window.navigator.userAgent.toString().toLowerCase();

          if (ua.indexOf("trident/6") > -1 || ua.indexOf("msie ") > -1) {
            window.location = "/unsupported";
          }
        `}</script>;
      </Helmet>

      <Header brand={brand} sticky={!!hero} />

      <main
        id="content"
        tabIndex="-1"
        css={css`
          // WHY: IE11 requires display: block; to be explicit
          display: block;

          ${!hero && createHeroPadding(false)}
        `}
      >

        {hero &&
          <TopBackground brand={brand}>
            {hero}
          </TopBackground>
        }

        {children}

      </main>

      <Footer showContactForm={showContactForm} />
    </>
  )
};

export default Layout;
