import React from "react";
import { Link } from "gatsby";

import { smoothScrollToId } from "../utils/links"

const SmartLink = ({
  link = ``,
  children,
  ...props
}) => {

  // WHY: Swap out the svclnk.com URL
  link = link.replace(/^https?:\/\/(www\.)?svclnk\.com\/?/, `/`);

  const isRelativeUrl = link.startsWith(`/`) && !link.startsWith(`//`);
  const isAnchor = link.startsWith(`#`);

  let LinkTag;

  if (isRelativeUrl) {
    LinkTag = Link;
    props.to = link;
  }

  else {
    LinkTag = `a`;

    if (link) {
      props.href = link;
    }

    if (isAnchor) {
      props.onClick = (e) => {
        e.preventDefault();

        smoothScrollToId(link);
      };
    }

    else {
      props.rel = `noopener`;
    }
  }

  return (
    <LinkTag {...props}>{children}</LinkTag>
  )
};

export default SmartLink;
