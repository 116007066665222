import React, { useState, useEffect, useRef } from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";

import { COLORS, BREAKPOINTS, BRANDS, TRANSITIONS, HEADER_HEIGHT, BREAKPOINT_SPACING } from "../../css-vars";

import { hexToRgba } from "../../utils/color.js";
import { pixelsToRem, mediaQuery, createSpacingUnitMediaQueries } from "../../utils/css-helpers.js";
import { formatContentfulMenu } from "../../utils/contentful";

import { homeUrl, exosUrl, loancareUrl, loginUrl } from "../../routes";
import { HiddenTextForScreenReader } from "../../utils/a11y.js";

import CallToAction from "../CallToAction";
import SmartLink from "../SmartLink";

import { ReactComponent as ServicelinkLogoSvgInline } from "./assets/servicelink-logo.svg";
import { ReactComponent as ExosLogoSvgInline } from "./assets/exos-logo.svg";
import { ReactComponent as LoancareLogoSvgInline } from "./assets/loancare-logo.svg";
import { ReactComponent as HamburgerSvgInline } from "./assets/hamburger.svg";
import Arrow from "./assets/arrow.svg";

const Header = ({
  brand = BRANDS.servicelink,
  sticky = true,
  ...props
}) => {

  const { allContentfulGlobalSiteConfig } = useStaticQuery(graphql`
    {
      allContentfulGlobalSiteConfig(limit: 1, sort: {
        fields: createdAt,
        order: DESC
      }) {
        edges {
          node {
            headerLinks {
              ...MenuList
            }
          }
        }
      }
    }
  `);

  const links = formatContentfulMenu(allContentfulGlobalSiteConfig, `header`);

  let backgroundColor;
  let logoLink;
  let logo;
  let logoLabel;
  switch (brand) {
    default:
      console.error(`Unexpected value in 'brand' parameter: ${brand}`);
      // Don't break and run 'servicelink' as default brand (directive for ESLint: falls through)
    case BRANDS.servicelink:
      backgroundColor = COLORS.dark;
      logoLink = homeUrl;
      logo = <ServicelinkLogoSvgInline css={css`
        // WHY: Logotype does not naturally align with sections, this compensates
        ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
          margin-top: ${pixelsToRem(-8)};
        }

        ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
          margin-top: ${pixelsToRem(-10)};
        }
      `} />;
      logoLabel = `ServiceLink`;
      break;
    case BRANDS.exos:
      backgroundColor = `#004F92`;
      logoLink = exosUrl;
      logo = <ExosLogoSvgInline />;
      logoLabel = `EXOS`;
      break;
    case BRANDS.loancare:
      backgroundColor = `#0576AF`;
      logoLink = loancareUrl;
      logo = <LoancareLogoSvgInline />;
      logoLabel = `LoanCare`;
      break;
  }

  const logoWidth = 200;
  const logoHeight = 50;

  const LOGO_SIZE_MODIFIERS = {
    tiny: 0.667,
    small: 0.75,
    medium: 1,
  };

  // This is set to null to begin with so as to not add an `aria-expanded` value
  // where it isn’t needed
  const [showSideMenu, setShowSideMenu] = useState(null);
  const [fixedMenu, setFixedMenu] = useState(true);
  const [activeSubMenu, setActiveSubMenu] = useState(-1);
  // Track if the focus is on a menu item (a11y: users can do it with the TAB key)
  // so we can keep the menu visible while this is true
  const [a11ySubMenu, setA11ySubMenu] = useState(-1);

  // Side menu is visible if user clicked on the hamburger OR if he focused a menu item with the keyboard
  const sideMenuVisible = (showSideMenu || a11ySubMenu >= 0);
  // Submenu is visible if user clicked on the parent section OR if he focused a subsection with the keyboard
  const subMenuVisible = (linkIndex) => (activeSubMenu === linkIndex || a11ySubMenu === linkIndex);

  // Effect managing menu closure with ESC key
  useEffect(() => {
    const handleUserKeyPress = ({ key }) => {
      if (key === `Escape` || key === `Esc`) { // WHY: IE11/Edge use `Esc`
        setShowSideMenu(false);
        setActiveSubMenu(-1);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [setShowSideMenu, setActiveSubMenu]);

  // Effect managing the auto-closure of menus when user clicks in an empty space
  useEffect(() => {
    const onClick = (event) => {
      // Elements that should be excluded from the "outside click" are tagged with some
      // special data-attributes, so they can be easily recognizable in the ancestors
      // of the currently clicked element
      if (event.target.closest(`[data-main-navigation],[data-hamburger-button]`) === null) {
        // User clicked outside the main navigation and the hamburger button
        setShowSideMenu(false);
      }
      if (event.target.closest(`[data-main-section],[data-sub-menu]`) === null) {
        // User clicked outside any main section and sub menu
        setActiveSubMenu(-1);
      }
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  // Effect managing the static/fixed transition of the header
  // The technique used relies on an empty div placed on top of the page to detect
  // when the top of the page is inside the viewport; we call this div "beacon"
  const beaconRef = useRef(null);
  useEffect(() => {
    // WHY: Don't trigger the fixed/sticky state if any of the following conditions are met:
    //      1. The beacon doesn't exist
    //      2. IntersectionObserver isn't supported (e.g. IE11)
    //      3. The prop `sticky` is set to false – the current view doesn't require a sticky header
    if (!beaconRef || !beaconRef.current || !('IntersectionObserver' in window) || !sticky) return;

    setFixedMenu(false);

    const observer = new IntersectionObserver(([entry], observer) => {
      if (entry.isIntersecting) {
        setFixedMenu(false);
      } else {
        setFixedMenu(true);
      }
    });
    observer.observe(beaconRef.current);

    return () => {
      observer.disconnect();
    }
  }, [beaconRef, setFixedMenu, sticky]);

  const sideMenuStyle = css`
    background: ${COLORS.primaryDark};
    position: fixed;
    display: flex;
    left: 0;
    height: 100%;
    top: 0;
    transition: transform 250ms ease-in-out;
    transform: translate(${sideMenuVisible ? '0' : '-100%'}, 0);
    will-change: transform; // WHY: Forces it onto a new layer to improve performance
    z-index: 1;
  `;

  const sideMenuChildStyle = css`
    overflow: auto;
    overscroll-behavior-y: contain;
    width: 100%;
  `;

  const headerVisibleSectionStyle = css`
    ${createSpacingUnitMediaQueries({
      'padding-left': `calc(SPACING_UNIT / 2)`,
      'padding-right': `calc(SPACING_UNIT / 2)`,
    })}

    height: ${pixelsToRem(HEADER_HEIGHT.tiny)};

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      height: ${pixelsToRem(HEADER_HEIGHT.small)};
      transform: translate3d(0, ${fixedMenu ? 0 : pixelsToRem(HEADER_HEIGHT.small / 3)}, 0);
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      height: ${pixelsToRem(HEADER_HEIGHT.medium)};
      transform: translate3d(0, ${fixedMenu ? 0 : pixelsToRem(HEADER_HEIGHT.medium / 3)}, 0);
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      height: ${pixelsToRem(HEADER_HEIGHT.large)};
      transform: translate3d(0, ${fixedMenu ? 0 : pixelsToRem(HEADER_HEIGHT.large / 3)}, 0);
    }

    display: flex;
    transition: transform 250ms ease-in-out;
    will-change: transform; // WHY: Forces it onto a new layer to improve performance
  `;

  const menuLinkItemPaddingLeftRight = pixelsToRem(BREAKPOINT_SPACING.tiny / 6);
  const menuLinkItemPaddingTopBottom = pixelsToRem(BREAKPOINT_SPACING.tiny / 4);

  const menuLinkItemStyle = css`
    margin-right: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 4)};
    position: relative;

    > a {
      color: currentColor;
      display: block;
      padding: ${menuLinkItemPaddingTopBottom} ${menuLinkItemPaddingLeftRight};

      :hover {
        text-decoration: underline;
      }
    }
  `;

  const menuLinkItemStyleSmall = css`
    > a {
      padding: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 4)} 0;

      ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
        padding: ${pixelsToRem(BREAKPOINT_SPACING.tiny * 0.334)} 0;
      }
    }
  `;

  const closeAllMenus = () => {
    // When this function is called because a user clicked on a page inside
    // a submenu, the click has already brought the focus on the link,
    // setting a11ySubMenu to the proper value; this is why, in order to
    // close all menus, we have to reset a11ySubMenu too
    setA11ySubMenu(-1);
    setActiveSubMenu(-1);
    setShowSideMenu(false);
  };

  const toggleSubMenu = (e, index) => {
    e.preventDefault();

    setActiveSubMenu(activeSubMenu === index ? -1 : index);
  };

  const closeHeader = e => {
    e.preventDefault();

    setShowSideMenu(false);
  };

  const skipToContent = e => {
    e.preventDefault();

    const main = document.getElementById(`content`);
    // WHY: As main is always at the top we don't need to calculate the coordinates
    window.scrollTo(0, 0);

    if (main) {
      main.focus();
    }
  };

  return (
    <>
      <div ref={beaconRef} aria-hidden="true" css={css`
        display: block;
        height: ${pixelsToRem(1)};
        margin-top: ${pixelsToRem(1)};
        margin-bottom: ${pixelsToRem(-2)};
      `} />

      <header
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: white;
          position: fixed;
          z-index: 999;
          width: 100%;

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {

            // WHY: Replicates the max-width of ResponsiveContainer
            padding: 0 calc((100% - ${pixelsToRem(BREAKPOINTS.large)}) / 2);
          }

          a:not([href="#contact"]) {

            :hover,
            :focus {
              text-decoration: underline;
            }
          }
        `}
        id="site-header"
      >

        {/* Background */}
        <div aria-hidden="true" css={css`
          position: fixed;
          background: ${backgroundColor};
          left: 0;
          width: 100%;
          opacity: ${fixedMenu ? 1 : 0};
          transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
          top: 0;
          height: ${pixelsToRem(HEADER_HEIGHT.tiny)};

          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            height: ${pixelsToRem(HEADER_HEIGHT.small * 2)};
            transform: translate3d(0, ${!fixedMenu ?
              pixelsToRem(HEADER_HEIGHT.small / -2) :
              pixelsToRem(HEADER_HEIGHT.small / -1)
            }, 0);
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
            height: ${pixelsToRem(HEADER_HEIGHT.medium * 2)};
            transform: translate3d(0, ${!fixedMenu ?
              pixelsToRem(HEADER_HEIGHT.medium / -2) :
              pixelsToRem(HEADER_HEIGHT.medium / -1)
            }, 0);
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
            height: ${pixelsToRem(HEADER_HEIGHT.large * 2)};
            transform: translate3d(0, ${!fixedMenu ?
              pixelsToRem(HEADER_HEIGHT.large / -2) :
              pixelsToRem(HEADER_HEIGHT.large / -1)
            }, 0);
          }
        `} />

        {/* Logo and hamburger */}
        <div css={css`
          ${headerVisibleSectionStyle};

          align-items: center;
          display: flex;
          margin-right: auto;
          position: relative;
          z-index: 2;

          ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
            width: 100%;
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
            padding-right: 0;
          }
        `}>
          <button
            data-hamburger-button
            onClick={() => setShowSideMenu(!showSideMenu)}
            title={showSideMenu ? `Hide Menu` : `Show Menu`}
            aria-label={sideMenuVisible ? `Hide Menu` : `Show Menu`}
            css={css`
              display: block;
              cursor: pointer;
              flex-shrink: 0;
              transform: rotate(${sideMenuVisible ? `90` : `0`}deg);
              transition: transform ${TRANSITIONS.regular}, opacity ${TRANSITIONS.regular};
              margin-left: ${pixelsToRem(-10)};
              margin-right: ${pixelsToRem(-40)};
              padding: ${pixelsToRem(10)};
              width: ${pixelsToRem(40)};

              ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                margin-right: ${pixelsToRem(BREAKPOINT_SPACING.small / 4)};
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
                display: none;
              }

              :focus:not(:active):not(:hover) {
                opacity: 0.5;
              }
            `}
          >
            <HamburgerSvgInline css={css`
              display: block;
              height: ${pixelsToRem(20)};
              width: ${pixelsToRem(20)};

              path {
                fill: currentColor;
              }
            `}/>
          </button>

          <h1 css={css`
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            z-index: 2;
          `}>
            <SmartLink id="header-logo" aria-labelledby="header-logo-label" link={logoLink} css={css`
              display: flex;
              transition: opacity ${TRANSITIONS.regular};

              svg {
                height: ${pixelsToRem(logoHeight * LOGO_SIZE_MODIFIERS.tiny)};
                width: ${pixelsToRem(logoWidth * LOGO_SIZE_MODIFIERS.tiny)};

                path {
                  fill: currentColor;
                }

                ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                  height: ${pixelsToRem(logoHeight * LOGO_SIZE_MODIFIERS.small)};
                  width: ${pixelsToRem(logoWidth * LOGO_SIZE_MODIFIERS.small)};
                }

                ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
                  height: ${pixelsToRem(logoHeight * LOGO_SIZE_MODIFIERS.medium)};
                  width: ${pixelsToRem(logoWidth * LOGO_SIZE_MODIFIERS.medium)};
                }
              }

              :focus:not(:active):not(:hover) {
                opacity: 0.5;
              }
            `}>
              {logo}
              <HiddenTextForScreenReader id="header-logo-label">{logoLabel}</HiddenTextForScreenReader>
            </SmartLink>
          </h1>
        </div>

        {/* Skip to Content */}
        <a href="#content" onClick={skipToContent} css={css`
          background: ${COLORS.secondaryA11y};
          z-index: 999;
          padding: 0.5em;
          position: absolute;
          top: 0;
          right: -100vw;

          :focus {
            right: 0;
          }
        `}>
          Skip to Content
        </a>

        {/* Navigation Elements */}
        {/* TODO: improve code formatting here */}
        <nav data-main-navigation aria-expanded={sideMenuVisible} css={css`
          position: static;

          // Up to BREAKPOINTS.small it will be this nav to draw the side menu...
          ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
            ${sideMenuStyle};

            padding-top: ${pixelsToRem(HEADER_HEIGHT.tiny)};
            width: calc(50% + ${pixelsToRem(logoWidth * LOGO_SIZE_MODIFIERS.tiny / 2 + 20)});
          }

          ${mediaQuery({ maxWidth: BREAKPOINTS.tiny })} {
            max-width: calc(100% - ${pixelsToRem(20)});
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
            ${headerVisibleSectionStyle};

            flex: 1;
            padding-left: 0 !important; // WHY: Specificty war caused by Emotion
          }
        `}>
          <div css={css`
            ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
              ${sideMenuChildStyle};

              padding: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 4)}
                ${pixelsToRem(BREAKPOINT_SPACING.tiny / 2)}
                ${pixelsToRem(BREAKPOINT_SPACING.tiny / 2)};
            }

            ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
              align-items: center;
              display: flex;
              margin-left: auto;
            }
          `}>
            <div css={css`
              // …but from BREAKPOINTS.small it will be this ul to draw it
              ${mediaQuery({ minWidth: BREAKPOINTS.small, maxWidth: BREAKPOINTS.large })} {
                ${sideMenuStyle};

                padding-top: ${pixelsToRem(HEADER_HEIGHT.small)};
                width: calc(${pixelsToRem(
                  (BREAKPOINT_SPACING.small / 2 * 2) + // WHY: <nav> padding for each side
                  (logoWidth * LOGO_SIZE_MODIFIERS.small) + // WHY: width of logo
                  40 + (BREAKPOINT_SPACING.small / 4) // WHY: Hamburger and margin-right
                )});
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.medium, maxWidth: BREAKPOINTS.large })} {
                padding-top: ${pixelsToRem(HEADER_HEIGHT.medium)};
                width: calc(${pixelsToRem(
                  (BREAKPOINT_SPACING.medium / 2 * 2) + // WHY: <nav> padding for each side
                  (logoWidth * LOGO_SIZE_MODIFIERS.medium) + // WHY: width of logo
                  40 + (BREAKPOINT_SPACING.medium / 4) // WHY: Hamburger and margin-right
                )});
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
                display: flex;
                align-items: center;
                margin-left: auto;
                margin-right: 0;
              }
            `}>
              <ul css={css`
                // WHY: It's not possible to use the createSpacingUnitMediaQueries here as it would
                //      cause a media-query within a media-query, which Emotion doesn't escape, and thus
                //      IE ignores rather than correcting like modern browsers do. The code generated
                //      is the same, but it adds a few more overheads to keep it up to date. The other
                //      instances of sideMenuChildStyle have something similar
                ${mediaQuery({ minWidth: BREAKPOINTS.small, maxWidth: BREAKPOINTS.large })} {
                  ${sideMenuChildStyle};

                  padding: ${pixelsToRem(BREAKPOINT_SPACING.small / 4)}
                    ${pixelsToRem(BREAKPOINT_SPACING.small / 2)}
                    ${pixelsToRem(BREAKPOINT_SPACING.small / 2)};
                }

                ${mediaQuery({ minWidth: BREAKPOINTS.medium, maxWidth: BREAKPOINTS.large })} {
                  padding: ${pixelsToRem(BREAKPOINT_SPACING.medium / 4)}
                    ${pixelsToRem(BREAKPOINT_SPACING.medium / 2)}
                    ${pixelsToRem(BREAKPOINT_SPACING.medium / 2)};
                }

                ${mediaQuery({ maxWidth: BREAKPOINTS.large })} {
                  > li {
                    display: block;
                  }
                }

                ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
                  align-items: center;
                  display: flex;
                }
              `}>
                {links.map((link, linkIndex) => (
                  <li
                    key={link.label}
                    css={css`
                      ${menuLinkItemStyle};

                      ${mediaQuery({ maxWidth: BREAKPOINTS.large })} {
                        ${menuLinkItemStyleSmall};
                      }
                    `}
                  >
                    {/* WHY: When a submenu exists the link for the page is included within the list itself */}
                    <SmartLink
                      data-main-section
                      partiallyActive={true}
                      activeClassName="isActive"
                      link={link.link}

                      onClick={link.subLinks ? e => toggleSubMenu(e, linkIndex) : null}
                      onFocus={link.subLinks ? null : () => setA11ySubMenu(linkIndex)}
                      onBlur={link.subLinks ? null : () => setA11ySubMenu(-1)}
                      tabIndex={link.subLinks ? -1 : null}

                      css={css`
                        cursor: ${link.subLinks ? `context-menu` : `pointer`};

                        ::before {
                          background: ${COLORS.primary};
                          bottom: 0;
                          content: '';
                          height: 100%;
                          left: ${pixelsToRem(BREAKPOINT_SPACING.tiny / -2)};
                          opacity: 0;
                          position: absolute;
                          width: ${pixelsToRem(4)};

                          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                            left: ${pixelsToRem(BREAKPOINT_SPACING.small / -2)};
                          }

                          ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
                            left: ${pixelsToRem(BREAKPOINT_SPACING.medium / -2)};
                          }
                        }

                        &.isActive {
                          ::before {
                            opacity: 1;
                          }
                        }

                        ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
                          ::before {
                            bottom: 0;
                            height: ${pixelsToRem(4)};
                            left: 0;
                            width: 100%;
                          }
                        }

                        ${link.subLinks && `
                          ::after {
                            content: '';
                            display: inline-block;
                            width: ${pixelsToRem(11)};
                            height: ${pixelsToRem(7)};
                            vertical-align: middle;
                            margin-left: ${pixelsToRem(6)};
                            background: url(${Arrow}) no-repeat top left;
                            transform: rotate(${subMenuVisible(linkIndex) ? `180` : `0`}deg);
                            transition: transform 250ms ease-in-out;
                          }
                        `}
                      `}
                    >
                      {link.label}
                    </SmartLink>
                    {link.subLinks && <ul
                      data-sub-menu
                      css={css`
                        line-height: 1.35;

                        // Apply to all li except the first, that is "Overview"
                        li:nth-of-type(n+2) a::before {
                          content: '';
                          display: inline-block;
                          width: ${pixelsToRem(13)};
                          height: ${pixelsToRem(2)};
                          background: ${COLORS.primary};
                          margin-right: ${pixelsToRem(15)};
                          vertical-align: middle;
                        }
                        a.isActive {
                          color: ${COLORS.primary};
                        }

                        ${mediaQuery({ maxWidth: BREAKPOINTS.large })} {
                          overflow: hidden;
                          max-height: ${subMenuVisible(linkIndex) ? pixelsToRem(200) : `0`};
                          transition: max-height 250ms ease-in-out;

                          a {
                            display: block;
                            padding: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 8)} 0;
                            color: ${hexToRgba(COLORS.lightest, 0.66)};
                          }
                        }

                        ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
                          background: ${hexToRgba(COLORS.darker, 0.95)};
                          border-radius: ${pixelsToRem(10)};
                          opacity: ${subMenuVisible(linkIndex) ? 1 : 0};
                          font-size: ${pixelsToRem(18)};
                          left: 50%;
                          max-width: ${pixelsToRem(300)};
                          max-height: calc(100vh - ${HEADER_HEIGHT.large} - ${pixelsToRem(50)});
                          width: 100vw;
                          min-width: max-content;
                          overflow: auto;
                          position: absolute;
                          pointer-events: ${subMenuVisible(linkIndex) ? `auto` : `none`};
                          padding: ${pixelsToRem(BREAKPOINT_SPACING.large / 10)};
                          transform: translate3d(-50%, ${subMenuVisible(linkIndex) ? pixelsToRem(10) : 0}, 0);
                          transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
                          top: ${pixelsToRem(50)};

                          li {
                            padding: ${pixelsToRem(BREAKPOINT_SPACING.large / 20)};
                          }

                          a {
                            color: currentColor;
                            display: block;
                          }
                        }
                      `}
                    >
                        <li>
                          <SmartLink
                            link={link.link}
                            activeClassName="isActive"
                            onClick={closeAllMenus}
                            onFocus={() => setA11ySubMenu(linkIndex)}
                            onBlur={() => setA11ySubMenu(-1)}
                            aria-label={`${link.label} overview`}
                          >
                            Overview
                          </SmartLink>
                        </li>
                        {link.subLinks.map((subLink, subLinkIndex) =>
                          <li key={`${linkIndex}-${subLinkIndex}`}>
                            <SmartLink
                              link={subLink.link}
                              activeClassName="isActive"
                              onClick={closeAllMenus}
                              onFocus={() => setA11ySubMenu(linkIndex)}
                              onBlur={() => setA11ySubMenu(-1)}
                            >
                              {subLink.label}
                            </SmartLink>
                          </li>
                        )}
                    </ul>}
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact Us Nav */}
            <ul css={css`
              ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
                // WHY: Creates a space between the menu items and the contact items
                margin-top: ${pixelsToRem(BREAKPOINT_SPACING.tiny)};

                > li {
                  display: block;
                  margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 4)};
                }
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.small, maxWidth: BREAKPOINTS.large })} {
                ${headerVisibleSectionStyle}
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }

              ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
                margin-left: ${pixelsToRem(BREAKPOINT_SPACING.large / 4)};
              }
            `}>
              <li css={css`
                ${menuLinkItemStyle}

                ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
                  ${menuLinkItemStyleSmall};
                }
              `}>
                <a href="tel:+1800-777-8759">(800) 777-8759</a>
              </li>
              <li css={css`
                ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                  margin-right: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 4)};
                }
              `}>
                <CallToAction
                  onClick={closeHeader}
                  caption="Contact Us"
                  arrow={false}
                  level="small"
                  link="#contact"
                  spacingTop="none"
                  spacingBottom="none"
                />
              </li>
              <li css={css`
                ${menuLinkItemStyle}

                ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
                  ${menuLinkItemStyleSmall};
                }

                // WHY: Moves the link back over by the padding to line up with the ResponsiveContainer
                ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                  margin-right: calc(${menuLinkItemPaddingLeftRight} * -1);
                }
              `}>
                <SmartLink link={loginUrl} css={css`font-weight: bold`}>Log In</SmartLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
};

export default Header;
