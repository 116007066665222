import React from "react"
import { css } from "@emotion/core"
import { BRANDS, BREAKPOINTS } from "../../css-vars";
import { mediaQuery, createSpacingUnitMediaQueries, pixelsToRem } from "../../utils/css-helpers";
import CutoutSvg from "./assets/cutout.svg";
import PatternSvg from "./assets/pattern.svg";


// All in pixels
const cutoutWidth = 2369;
const cutoutHeight = 402;
const patternWidth = 31;
const patternHeight = 31;
const patternX = 15;
const patternY = 15;

const TopBackground = ({
  brand = BRANDS.servicelink,
  ...props
}) => {

  let backColor;
  let frontGradient1;
  let frontGradient2;
  switch (brand) {
    default:
      console.error(`Unexpected value in 'brand' parameter: ${brand}`);
      // Don't break and run 'servicelink' as default brand (directive for ESLint: falls through)
    case BRANDS.servicelink:
      backColor = `#001E60`;
      frontGradient1 = `linear-gradient(45deg, rgba(0, 44, 142, .75), rgba(0, 30, 96, 1))`;
      // Fake transparent background, not in use for ServiceLink brand
      frontGradient2 = `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))`;
      break;
    case BRANDS.exos:
      backColor = `#009ADE`;
      frontGradient1 = `linear-gradient(45deg, rgba(0, 154, 222, .86) 10%, rgba(0, 154, 222, 1))`;
      frontGradient2 = `linear-gradient(to bottom, rgba(0, 30, 96, .6), rgba(0, 30, 96, 0) 50%)`;
      break;
    case BRANDS.loancare:
      backColor = `#12C6E7`;
      frontGradient1 = `linear-gradient(45deg, rgba(18, 198, 231, .86) 10%, rgba(0, 154, 222, 1))`;
      frontGradient2 = `linear-gradient(to bottom, rgba(0, 30, 96, .6), rgba(0, 30, 96, 0) 50%)`;
      break;
  }

  return <div {...props} css={css`
    // The large cutout of this unit introduces an excess of negative space that makes the next section
    // look too far away from the top of the page. We use some negative margin to compensate that.
    ${createSpacingUnitMediaQueries({'margin-bottom': `calc(SPACING_UNIT * -0.5)`})}

    background:
      url(${CutoutSvg}) no-repeat 47% calc(100% + 16rem),
      ${frontGradient2},
      ${frontGradient1},
      url(${PatternSvg}) ${pixelsToRem(patternX)} ${pixelsToRem(patternY)},
      // Shorthand doesn't support solid colors, so here is a fake gradient
      linear-gradient(${backColor}, ${backColor});
    background-size:
      ${pixelsToRem(cutoutWidth)} ${pixelsToRem(cutoutHeight)},
      auto,
      auto,
      ${pixelsToRem(patternWidth)} ${pixelsToRem(patternHeight)},
      auto;
    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      background-size:
        160% 15.63rem,
        auto,
        auto,
        ${pixelsToRem(patternWidth)} ${pixelsToRem(patternHeight)},
        auto;
      background-position:
        left calc(100% + ${pixelsToRem(2)}),
        0 0,
        0 0,
        ${pixelsToRem(patternX)} ${pixelsToRem(patternY)},
        0 0;
    }
    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      background-size:
        // We want the "tip" of the cutout to stay always under the hero image,
        // but for very wide viewports we need special adjustments. This formula
        // was obtained empirically, it's just good enough to keep the tip in place.
        calc(160% - (100% - ${pixelsToRem(BREAKPOINTS.large)}) / 2) ${pixelsToRem(cutoutHeight)},
        auto,
        auto,
        ${pixelsToRem(patternWidth)} ${pixelsToRem(patternHeight)},
        auto;
      // Add enough padding to leave room for the "Find out more" link
      padding-bottom: 4vw;
    }
  `} />
};

export default TopBackground;
