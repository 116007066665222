export const extractFlourishColor = (contentfulFlourishColorLabel) => {
  // Contentful values are enforced to be like this: "Azure (##009ADE)"
  // so we can extract the color from the parenthesis
  return contentfulFlourishColorLabel.match(/.*\((#[0-9a-f]{6})\)/i)[1];
};

/**
 * Formats the GraphQL object for footerLinks/headerLinks from the global configuration into a usable Array
 *
 * @param {Object} allContentfulGlobalSiteConfig – The object provided by Contentful for the request
 * @param {Array} allContentfulGlobalSiteConfig.edges – The array of edges to be processed
 * @param {String} menuId – The name of the menu to get (i.e. header, footer)
 * @return {Array} The formatted array matching the structure from the original navigation.js
 */
export const formatContentfulMenu = ({ edges }, menuId) => {
  const [edge] = edges;
  if (!edge) return [];

  // WHY: This grabs the named array based on the menuId that corresponds with Contentful
  //      i.e. footer -> footerLinks
  const {
    [`${menuId}Links`]: links,
  } = edge.node;

  return links.reduce((acc, item) => {
    const {
      id,
      parentLink,
      childLinkList: subLinks,
    } = item;

    const {
      link,
      label,
    } = parentLink;

    const parent = {
      id,
      link,
      label,
      subLinks,
    };

    acc.push(parent);

    return acc;
  }, []);
};
