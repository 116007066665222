import React from "react";
import { css } from "@emotion/core";

import { BREAKPOINTS } from "../css-vars";
import { mediaQuery, pixelsToRem, createHeroPadding } from "../utils/css-helpers";

import ResponsiveContainer from "./ResponsiveContainer";
import FindOutMore from "./FindOutMore";
import Heading from "./Heading";

const Hero = ({
  title,
  photo,
  showFindOutMore = true
}) => {
  return (
    <ResponsiveContainer spacingTop="none" spacingBottom="none">
      <div css={css`
        color: white;

        ${createHeroPadding()};

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          display: flex;
        }
      `}>

        <div css={css`
          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            display: flex;
            flex-direction: column;
            padding-bottom: 10%;
            width: ${photo ? 50 : 65}%;
          }

          ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
            padding-bottom: calc(3rem + 6.66%);
            width: ${photo ? 50 : 60}%;
          }
        `}>
          <Heading
            align="center"
            tag="h1"
            spacingTop="half"
            css={css`
              color: currentColor;
              margin-left: auto;
              margin-right: auto;

              ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
                margin-bottom: auto;
                margin-top: auto;
                margin-left: 0;
                text-align: left;
                padding: 0.5em 0; // WHY: Ensures that on tight screens it isn't cramped
              }
            `}
          >
            {title}
          </Heading>
          <FindOutMore
            hidden={!showFindOutMore}
            css={css`
              // Some pages (like Login) need to hide the FindOutMore.
              // Setting "display: none" the header becomes shorter and looks
              // bad (especially in the login page); better to just set
              // "visibility: hidden" to maintain spacing, through the "hidden"
              // HTML attribute for better accessibility.
              &[hidden] {
                display: block;
                visibility: hidden;
              }

              margin-top: ${pixelsToRem(16)};

              ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
                display: none;
              }
          `}/>
        </div>

        <div css={css`
          margin-top: -5%; // WHY: Moves the hero image up a little to accomodate the space created
          min-height: ${pixelsToRem(165)};
          ${mediaQuery({minWidth: BREAKPOINTS.small})} {
            flex: 1;
          }
        `}>
          {photo}
        </div>

      </div>
    </ResponsiveContainer>
  )
};

export default Hero;
