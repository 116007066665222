import React from "react";
import { css } from "@emotion/core";
import { responsiveFontSizes } from '../utils/css-helpers';
import { COLORS, FONT_FAMILIES, DEFAULT_FONT_SIZES_MAP } from "../css-vars";

const Paragraph = ({
  children,
  align = 'left',
  tag = `p`,
  level = `regular`,
  spacingTop = `full`,
  spacingBottom = `full`,
  ...props
}) => {

  const ParagraphTag = tag;
  const [
    fontSizeSmall,
    fontSizeLarge,
  ] = DEFAULT_FONT_SIZES_MAP[level];

  const SPACING_MAP = {
    none: 0,
    half: 0.5,
    full: 1,
  };

  return (
    <ParagraphTag css={css`
      --spacing-top: ${SPACING_MAP[spacingTop]};
      --spacing-bottom: ${SPACING_MAP[spacingBottom]};

      ${responsiveFontSizes(fontSizeSmall, fontSizeLarge)}

      color: ${COLORS.dark};
      font-family: ${FONT_FAMILIES.body};
      line-height: 1.9em;
      margin-left: ${align === `left` ? 0 : `auto`};
      margin-right: ${align === `right` ? 0 : `auto`};
      margin-top: calc(1em * ${SPACING_MAP[spacingTop]});
      margin-bottom: calc(1em * ${SPACING_MAP[spacingBottom]});
      max-width: 46em;
      text-align: ${align};

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      u {
        text-decoration: underline;
      }

      a {
        color: ${COLORS.primaryA11y};
        text-decoration: underline;
        transition: color 250ms ease-in-out;

        :hover,
        :focus {
          color: ${COLORS.primaryDark};
        }

        :active,
        :visited {
          color: currentColor;
        }
      }
    `} {...props}>
      {children}
    </ParagraphTag>
  )
};

Paragraph.displayName = `Paragraph`;

export default Paragraph;
