import React from "react";
import { css } from "@emotion/core";

import { responsiveFontSizes } from '../utils/css-helpers';
import { COLORS } from "../css-vars";

import Strapline from "./Strapline";

const FONT_SIZES_MAP = {
  h1: [34, 72],
  h2: [26, 48],
  h3: [20, 39],
  h4: [18, 30],
  h5: [16, 19],
};

const MAX_WIDTH_MAP = {
  h1: `12em`,
  h2: `18em`,
  h3: `22em`,
  h4: `26em`,
  h5: `36em`,
};

const SPACING_MAP = {
  none: 0,
  half: 0.5,
  full: 1,
};

const Heading = ({
  children,
  align = `left`,
  level,
  tag = `h2`,
  strapline,
  spacingTop = `full`,
  spacingBottom = `full`,
  ...props
}) => {

  if (!level) {
    level = tag;
  }

  const HeadingTag = tag;

  let [
    fontSizeSmall,
    fontSizeLarge,
  ] = FONT_SIZES_MAP[level];
  let maxWidth = MAX_WIDTH_MAP[level];

  // In simple scenarios where the only child passed is a string perform some
  // fine tunings for long titles
  if (level === `h1` && typeof children === `string` && children.length > 50) {
    const fontSizeModifier = children.length > 85 ? 0.8 : 0.85;
    const maxWidthModifier = children.length > 85 ? 1.4 : 1.3;

    fontSizeSmall *= fontSizeModifier;
    fontSizeLarge *= fontSizeModifier;

    maxWidth = `calc(${maxWidth} * ${maxWidthModifier})`;
  }

  let {
    className = ``,
  } = props;

  // WHY: This is added to match the class in GlobalCss.js that forces the headings to behave differently
  if (level !== `h5`) {
    className += ` _heading-override`;
  }

  return (
    <HeadingTag
      {...props}
      className={className}

      css={css`
        ${responsiveFontSizes(fontSizeSmall, fontSizeLarge)}

        color: ${COLORS.dark};
        margin-top: calc(1.5em * ${SPACING_MAP[spacingTop]});
        margin-bottom: calc(0.66em * ${SPACING_MAP[spacingBottom]});
        margin-left: ${align === `left` ? 0 : `auto`};
        margin-right: ${align === `right` ? 0 : `auto`};
        max-width: ${maxWidth};
        position: relative;
        text-align: ${align};

        ${level === `h5` && `
          font-weight: 500;
          line-height: 1.6em; // TODO: Var
        `}
      `}
    >
      {strapline && <Strapline>{strapline}</Strapline>}
      {children}
    </HeadingTag>
  )
};

export default Heading;
