import { BREAKPOINTS, BREAKPOINT_SPACING, HEADER_HEIGHT } from "../css-vars";

const getRootFontSize = () => getComputedStyle(document.documentElement).fontSize.replace('px', '');

/**
 * Convert pixel value to rems by dividing by 16 with an optional removal of the `rem` unit
 *
 * @param  {Number} pixels - The value in pixels to return
 * @param  {Boolean} unit=true - Whether to include the unit
 * @return {Number|String} The pixel value converted to rems with the units where desired as a string or as a number when not desired
 */
export const pixelsToRem = (pixels, unit = true) => {
  if (isNaN(pixels)) return;

  const rem = pixels / 16;

  return unit ? `${rem}rem` : rem;
};

/**
 * Convert rem values to pixels
 * @param  {string}  rem  - The value in rem to be converted
 * @param  {Boolean} unit - Whether to include 'px' in the output
 * @return {(string|number)} - When unit is true, a string of the converted value suffixed with 'px'; otherwise, just
 * the converted value.
 */
export const remToPixels = (rem, unit = false) => {
  const rootFontSize = getRootFontSize();
  const px = rem.replace('rem', '') * rootFontSize;

  return unit
    ? `${px}px`
    : px;
}

/**
 * Convert pixel value to percentage relative to the container, with an optional removal of the `%` unit
 *
 * @param  {Number} pixels - The value in pixels to return
 * @param  {Number} containerPixels - The size in pixel of the container your are relating to
 * @param  {Boolean} unit=true - Whether to include the unit
 * @return {Number|String} The pixel value converted to % with the unit where desired as a string or as a number when not desired
 */
export const pixelsToPerc = (pixels, containerPixels, unit = true) => {
  if (isNaN(pixels) || isNaN(containerPixels)) return;

  const perc = pixels / containerPixels * 100;

  return unit ? `${perc}%` : perc;
};


/**
 * Creates a media query string for use in CSS from an object of queries with the ability to remove the default conversion to ems (not rems to ensure that it works in Safari)
 *
 * @param  {Object} queries - An object of queries which are key/value pairs of camelCase CSS selectors and pixel Number values (or any CSS unit if convertToEm is set to false)
 * @param  {Boolean} convertToEm=true - Changes the Number values from the queries object to be ems (from an original pixel value without the unit)
 * @return {String} A media query string to be used in CSS
 */
export const mediaQuery = (queries, convertToEm = true) => {
  const convertQueriesToString = (acc, key, index) => {
    const queryKey = key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

    const value = /^max-/.test(queryKey) ?
      queries[key] - 1 : // WHY: Removes 1px for max-* queries to ensure no overlap
      queries[key];

    const queryValue = convertToEm ?
      `${value / 16}em` :
      value;

    const prefix = index === 0 ? `@media` : `and`;

    return `${acc} ${prefix} (${queryKey}: ${queryValue})`;
  };

  return Object.keys(queries).reduce(convertQueriesToString, ``);
};


/**
 * Creates a series of media queries to be imported into the CSS that control the font-size through different (pre-defined) sizes starting at the smallest size defined, and increasing to the largest
 *
 * @param  {Number} small - The desired size (in pixels) on screens smaller than the tiny breakpoint
 * @param  {Boolean} large - The desired size (in pixels) on screens at the medium breakpoint
 * @return {String} A media query string to be used in CSS with a default font-size for small devices
 */
export const responsiveFontSizes = (small, large) => {
  let string = `font-size: ${pixelsToRem(small)};`;

  // TODO: Maybe make this available as it might be needed elsewhere to correlate with other font changes
  const fontSizeBreakpoints = [
    BREAKPOINTS.tiny, // 420
    BREAKPOINTS.small, // 768
    960,
    1280,
    1442,
  ];

  const fontSizeBreakpointsLength = fontSizeBreakpoints.length;

  const sizeJump = (large - small) / fontSizeBreakpointsLength;

  return fontSizeBreakpoints.reduce((acc, minWidth, index) => {
    index++;

    let fontSizePixel = small + (index * sizeJump);

    if (index === fontSizeBreakpointsLength) {
      fontSizePixel = large;
    }

    const query = ` ${mediaQuery({ minWidth })} {
      font-size: ${pixelsToRem(Math.round(fontSizePixel))};
    }`;

    return `${acc}${query}`;
  }, string);
};


/**
 * Creates a string from an object of CSS declarations where the string SPACING_UNIT
 * is substituted in with the value of that particular breakpoint. This is to polyfill
 * the CSS Custom Properties approach for IE11 used originally by only changing
 * --spacing-unit at each breakpoint. This instead creates breakpoints for each instance
 * to create the same reusable behaviour without the ability to use CSS Custom Properties.
 *
 * @param  {Object} properties - An object of CSS declarations
 * @param  {String} properties.property - A CSS property (full kebab-case, NOT camelCase)
 * @param  {String} properties.value – The value to manipulate (must include SPACING_UNIT)
 *                                     for replacement
 * @param  {Object} args – Arguments to change and augment the behaviour of the default function
 * @param  {Array}  args.excludeBreakpoints – An array of breakpoints (from the options below)
 *                                            to be ignored when creating the string
 * @param  {Array}  args.includeMin – An object (with keys equal to the breakpoints) that inserts
 *                                    the CSS string from that breakpoint onward. This is useful
 *                                    for instances where there are properties that are not
 *                                    transformed by the function, but that are styled
 *                                    responsively.
 * @return {String} A string of CSS declarations to be used within css() that has a redeclared
 *                  spacing for each breakpoint
 */
export const createSpacingUnitMediaQueries = (properties, args = {}) => {
  const {
    excludeBreakpoints = [],
    includeMin = {},
  } = args;

  const breakpointDeclarations = {
    tiny: ``,
    small: ``,
    medium: ``,
    large: ``,
  };

  const propertyKeys = Object.keys(properties);
  const breakpointKeys = Object.keys(BREAKPOINT_SPACING);

  propertyKeys.forEach((property, i) => {
    const preValue = properties[property];

    breakpointKeys.forEach(breakpoint => {
      const includeMinForBreakpoint = i === 0 && includeMin[breakpoint];
      if (excludeBreakpoints.includes(breakpoint)) {

        if (includeMinForBreakpoint) {
          breakpointDeclarations[breakpoint] += includeMinForBreakpoint;
        }

        return;
      }

      const spacingUnit = pixelsToRem(BREAKPOINT_SPACING[breakpoint]);
      const value = preValue.replace(/SPACING_UNIT/g, spacingUnit);

      let string = ``;

      // WHY: For breakpoints above the smallest we wrap them in a media-query
      //      so here (and below) we wrap all of the declarations for that
      //      specific breakpoint if those conditions are met
      if (i === 0 && breakpoint !== `tiny`) {
        string += `${mediaQuery({ minWidth: BREAKPOINTS[breakpoint] })} { `;
      }

      string += `${property}: ${value}; `;

      if (includeMinForBreakpoint) {
        string += includeMinForBreakpoint;
      }

      if (i >= propertyKeys.length - 1 && breakpoint !== `tiny`) {
        string += ` }`;
      }

      breakpointDeclarations[breakpoint] += string;
    });
  });

  // WHY: Reduce the object of breakpoints into a single string to return
  return breakpointKeys.reduce((acc, k) => acc += breakpointDeclarations[k], ``);
};


// TODO: Document (maybe move to a separate file for IE polyfills?)
const createArrayFromNumber = len => [...Array(len).keys()];

export const createAutoPopulatedGrid = (items, args = {}) => {
  const {
    columns = 2,
    columnGap = `7%`,
    rowGap = pixelsToRem(20),
  } = args;

  const rows = Math.ceil(items / columns);

  const columnTemplate = createArrayFromNumber(columns - 1).reduce((a, k) => `${a} ${columnGap} 1fr`, `1fr`);

  const baseCss = `
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: ${columnTemplate};
    grid-template-columns: ${columnTemplate};

    > :nth-of-type(n) {
      margin-bottom: ${rowGap};
    }
  `;

  const rowsCss = createArrayFromNumber(columns).reduce((str, i) => {
    const column = i * 2 + 1;
    str += `
      > :nth-of-type(${columns}n-${columns - i - 1}) {
        -ms-grid-column: ${column};
        grid-column: ${column};
      }
    `;
    return str;
  }, ``);

  const columnsCss = createArrayFromNumber(rows).reduce((str, i) => {
    const row = i + 1;
    str += `
      > :nth-of-type(n+${i * columns + 1}) {
        -ms-grid-row: ${row};
        grid-row: ${row};

        ${row < rows ? `` : `
          margin-bottom: 0;
        `}
      }
    `;

    return str;
  }, ``);

  return `
    ${baseCss}
    ${rowsCss}
    ${columnsCss}
  `;
};


/**
 * Creates a string of CSS declarations for adding padding equal to that in the Hero component
 *
 * @return {String} A string with CSS for use in Emotion’s css()
 */
export const createHeroPadding = (sticky = true) => {
  // WHY: When the header is always fixed rather than sticky we only need to have space for the
  //      header itself
  const modifier = sticky ? 1.5 : 1;

  return `
    padding-top: ${pixelsToRem(HEADER_HEIGHT.tiny * modifier)};

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      padding-top: ${pixelsToRem(HEADER_HEIGHT.small * modifier)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      padding-top: ${pixelsToRem(HEADER_HEIGHT.medium * modifier)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      padding-top: ${pixelsToRem(HEADER_HEIGHT.large * modifier)};
    }`;
}
