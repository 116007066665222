import React from "react"
import { Global, css } from "@emotion/core"
import "typeface-roboto";
import { FONT_FAMILIES, BRANDS } from "../css-vars";

const GlobalCss = ({
  brand
}) => {

  const brandFontObject = FONT_FAMILIES.heading[brand];

  return (
    <Global styles={css`
      html {
        color: #001E60;
        font: normal 100%/1 ${FONT_FAMILIES.body};
        text-decoration: none;
        box-sizing: border-box;
      }
      * {
        margin: 0;
        padding: 0;
        outline: 0;
        border: none;
        box-sizing: inherit;
        color: inherit;
        font: inherit;
        text-decoration: inherit;
        list-style: none;
        background: none;
      }

      // WHY: This is a horrible hack to create the functionality previously catered for
      //      by CSS Custom Properties, this came at the sacrifice of the modifier, but as it was
      //      only used to change it to 0.95 (which rounded to the same in most instances) this
      //      trade-off is acceptable. It also requires an extra level of specificty,
      //      so it is wrapped by body
      body {
        ._heading-override {
          font-family: ${brandFontObject.family};
          line-height: ${brandFontObject.lineHeight};
          font-weight: ${brandFontObject.weight};
        }
      }

      #___gatsby {
        overflow: hidden; // WHY: Stops elements overflowing and breaking the page width
      }

      // This class is added to the body element when the window is resized (see gatsby-browser.js)
      // and cancels all animations / transitions during the resizing, preventing glitches
      body.no-animations-during-resize * {
        animation: none !important;
        transition: none !important;
      }

      // WHY: Named sol-head-medium to avoid any chance of it being downloaded unintentionally from Github
      @font-face {
        src: url('/fonts/sol-head-medium.woff2') format("woff2"),
          url('/fonts/sol-head-medium.woff') format("woff");
        font-family: sole-headline;
        font-style: normal;
        font-weight: 500;
      }

      ${brand === BRANDS.exos && `
        // WHY: Named circ to avoid any chance of it being downloaded unintentionally from Github
        @font-face {
          src: url('/fonts/circ-bold-subset.woff2') format("woff2"),
            url('/fonts/circ-bold-subset.woff') format("woff");
          font-family: circular;
          font-style: normal;
          font-weight: 600;
        }`
      }

      // Little accessibility test to ensure role="presentation" is on all images (that aren't
      // provided by Gatsby). It should only be enabled on dev!
      // *:not(picture) > img[alt=""]:not([role="presentation"]) {
      //   border: 2px solid red !important;
      // }
    `}/>
  );
};

export default GlobalCss;
