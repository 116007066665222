/**
 * Converts an rgb triplet into a hex code
 *
 * @param {Number} r - The red value
 * @param {Number} g – The green value
 * @param {Number} b – The blue value
 * @return {String} The converted hex code
 */
export const rgbToHex = (r, g, b) => [r, g, b]
  .reduce((h, v) => `${h}${v.toString(16).toUpperCase().padStart(2, `0`)}`, `#`);

/**
 * Converts a hex code (short-hand accepted) into an rgb triplet
 *
 * @param  {String} h - The hex color code
 * @return {Array} The red, green, and blue values as an array
 */
export const hexToRgb = h => h
  // WHY: Converts shorthand into a full hex code
  .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
  .substring(1).match(/.{2}/g)
  .map(x => parseInt(x, 16));

/**
 * Converts a hex code (short-hand accepted) into an rgb triplet with an alpha layer
 *
 * @param  {String} h - The hex color code
 * @param  {Number} a – The alpha opacity (0–1)
 * @return {Array} The rgba value as a string for use in CSS
 */
export const hexToRgba = (h, a) => `rgba(${hexToRgb(h).join(`, `)}, ${a})`;
