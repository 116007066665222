import React from "react";
import { css } from "@emotion/core";

import { responsiveFontSizes, pixelsToRem } from '../utils/css-helpers.js';
import { smoothScrollToId } from "./../utils/links.js"
import { blinkAnimation } from "../css-vars";

const scrollToFindOutMore = (event) => {
  event.preventDefault();

  smoothScrollToId(`content-start`);
};
const a11ySupportHandler = (event) => {
  if (event.key === 'enter') return scrollToFindOutMore(event);
};

const FindOutMore = (props) => {
  return (
    <a
      {...props}
      href="#content-start"
      onClick={scrollToFindOutMore}
      onKeyPress={a11ySupportHandler}
      css={css`
        margin-bottom: ${pixelsToRem(-29)};

        :focus {
          opacity: 0.5;
        }
      `}
    >
      <div css={css`
        ${responsiveFontSizes(16, 22)}

        font-weight: bold;
        margin-bottom: 1em;
      `}>Find Out More</div>
      <svg
        viewBox="0 0 30 29"
        aria-hidden="true"
        css={css`
          display: block;
          fill: currentColor;
          width: ${pixelsToRem(30)};
          height: ${pixelsToRem(29)};
        `}
      >
        <polygon
          css={css`animation: ${blinkAnimation} 2s infinite; animation-delay: 1s`}
          points="26.8602012 13.7420738 29.1397988 16.2579262 15 29.0699065 0.860201167 16.2579262 3.13979883 13.7420738 15 24.489"/>
        <polygon
          css={css`animation: ${blinkAnimation} 2s infinite`}
          points="26.8602012 0.74207375 29.1397988 3.25792625 15 16.0699065 0.860201167 3.25792625 3.13979883 0.74207375 15 11.488"/>
      </svg>
    </a>
  );
};

export default FindOutMore;
