import React from "react"

import MarketoForm from "../components/MarketoForm";
import Hero from "../components/Hero";
import Layout from "../components/Layout"
import ResponsiveContainer from "../components/ResponsiveContainer";
import SEO from "../components/Seo"

export const privacyOptOutUrl = `/privacy-data-collection-opt-out-request/`;

const PrivacyOptOut = () => {
  const hero = (
    <Hero
      title="Privacy Data Collection Opt-Out Request"
      showFindOutMore={false}
    />
  );
  return (
    <Layout hero={hero} showContactForm={false}>
      <SEO title="Privacy Data Collection Opt-Out Request" />
      <ResponsiveContainer width="thin" spacingTop="half">
        <MarketoForm
          formId="1110"
          heading="Opt-Out"
          message="If you no longer wish for ServiceLink to collect information regarding your usage of our applications, please complete the form below and submit. All fields are required."
          successHeading="Request Received"
          successMessage="Thank you for submitting your request. It has been received by ServiceLink."
        />
      </ResponsiveContainer>
    </Layout>
  );
}

export default PrivacyOptOut;
