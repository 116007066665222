import React from "react";
import { css } from "@emotion/core";
import { responsiveFontSizes } from '../utils/css-helpers';
import { FONT_FAMILIES, COLORS } from "../css-vars";

const Strapline = ({
  children,
  ...props
}) => {
  return (
    <span {...props} css={css`
      ${responsiveFontSizes(10, 15)}

      color: ${COLORS.primaryA11y};
      display: block;
      font-weight: bold;
      font-family: ${FONT_FAMILIES.body};
      letter-spacing: ${0.25 / 16}em;
      line-height: 1;
      margin-bottom: 1.25em;
      text-transform: uppercase;
    `}>
      {children}
    </span>
  )
};

export default Strapline
