/* global MktoForms2 */

import React, { useEffect, useState, useRef } from "react";
import { css } from "@emotion/core";

import IconSelectDropdownSvg from "./assets/select-dropdown.svg";
import CtaArrowSvg from "../CallToAction/assets/arrow.svg";

import { hexToRgba } from "../../utils/color";
import { mediaQuery, pixelsToRem, responsiveFontSizes, createSpacingUnitMediaQueries } from "../../utils/css-helpers";
import { smoothScrollToId } from "../../utils/links";

import { BREAKPOINTS, COLORS, DEFAULT_FONT_SIZES_MAP, TRANSITIONS } from "../../css-vars";
import { hoverTransform } from "../CallToAction";

import Heading from "../Heading";
import Paragraph from "../Paragraph";


const removeJunk = (form) => {
  [
    ...form.getElementsByClassName('mktoClear'),
    ...form.getElementsByClassName('mktoGutter'),
    ...form.getElementsByClassName('mktoOffset'),
  ].forEach((node) => node.remove());
};

const toggleMessageField = (form) => {
  const textareas = form.getElementsByTagName(`textarea`);

  if (!textareas.length) return;

  const [messageField] = textareas;

  const column = messageField.closest('.mktoFormCol');
  const row = column.closest('.mktoFormRow');

  column.classList.add(`isMessageField`);

  row.hidden = true;

  const showMessageField = () => {
    row.hidden = false;
    form.removeEventListener('change', showMessageField);
  };

  form.addEventListener('change', showMessageField);
};

const togglePlaceholderStyle = ({ currentTarget: select }) => {
  const { value } = select;

  select.classList.toggle('isPlaceholder', !value);
};

const fixDropdowns = (form) => {
  for (const select of form.getElementsByTagName('select')) {
    if (select.classList.contains('isDropdown')) continue;

    if (!select.value) select.classList.add('isPlaceholder');

    const [placeholderOption] = select.options;

    if (!placeholderOption.value) {
      placeholderOption.innerHTML = `Select…`;
    }

    select.classList.add(`isDropdown`);

    select.addEventListener('change', togglePlaceholderStyle)

    // WHY: Conditional fields exist based on selects, but are not present in the DOM on load,
    //      so we have to check for their existence based on selects changing
    select.addEventListener('change', () => {
      const unfixedDropdowns = form.querySelectorAll(`select:not(.isDropdown)`);
      if (unfixedDropdowns.length) {
        fixDropdowns(form);
      }
    });
  }
};

const [
  fontSizeSmall,
  fontSizeLarge,
] = DEFAULT_FONT_SIZES_MAP.regular;


const MarketoForm = ({
  formId,
  heading = ``,
  message = ``,
  successHeading = heading,
  successMessage = message,
  ...props
}) => {

  const [formSuccess, setFormSuccess] = useState(false);
  const formWrapper = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.onload = () => {
      MktoForms2.loadForm("//app-ab35.marketo.com", "991-WDA-741", formId, (form) => {

        const formEl = form.getFormElem()[0];
        removeJunk(formEl);
        toggleMessageField(formEl);
        fixDropdowns(formEl);

        form.onSuccess((values, followUpUrl) => {

          const formEl = form.getFormElem();
          const formWrapperEl = formWrapper.current;

          formWrapperEl.style.height = `${formWrapperEl.getBoundingClientRect().height}px`;
          formWrapperEl.style.willChange = `height`;

          formEl.remove();

          setTimeout(() => {
            formWrapperEl.style.height = `0px`;
            smoothScrollToId(`mkto-form-${formId}`);
          }, 10);

          setFormSuccess(true);

          return false;
        });
      });
    };
    // Always better to set src as last thing, since it immediately triggers the download
    script.async = true;
    script.src = "//app-ab35.marketo.com/js/forms2/js/forms2.min.js";
    // Load Marketo pnly when the user is close to see it (gives huge boost in page load performance)
    let observer = null;
    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && !document.body.contains(script)) {
          document.body.appendChild(script);
        }
      }, {
        // This starts the loading way before the form area becomes visible,
        // so it can be rendered before the user sees it
        rootMargin: "200%"
      });
      observer.observe(formWrapper.current);
    } else {
      // For older browser not supporting IntersectionObserver load it immediately
      document.body.appendChild(script);
    }

    return () => {
      if (observer) observer.disconnect();
      if (document.body.contains(script)) document.body.removeChild(script);
    }
  }, [formId, formWrapper, setFormSuccess]);

  return (
    <section
      {...props}
      id={`mkto-form-${formId}`}
      css={css`
        .mktoForm {
          ${createSpacingUnitMediaQueries({
            'margin-top': `calc(SPACING_UNIT / 4)`,
          }, {
            includeMin: {
              tiny: `margin-left: auto; margin-right: auto;`,
            },
          })}

          -ms-grid-row: 1;
          -ms-grid-column: 1;
          grid-row: 1;
          grid-column: 1;

          color: ${COLORS.mid} !important;
          padding: 0;
          opacity: ${formSuccess ? 0 : 1};
          width: 100% !important;

          [hidden] {
            display: none;
          }
        }

        .mktoFormRow {
          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            display: -ms-grid;
            -ms-grid-columns: 1fr ${pixelsToRem(30)} 1fr;

            display: grid;
            grid-template-columns: 1fr ${pixelsToRem(30)} 1fr;

            max-width: ${pixelsToRem((390 * 2) + 60)}
          }
        }

        .mktoButtonRow {
          display: block;
        }

        .mktoFormCol,
        .mktoButtonWrap {
          float: none !important;
          margin: 0 !important;
        }

        .mktoFormCol {
          margin-bottom: ${pixelsToRem(30)} !important; // WHY: Specificity war
          max-width: ${pixelsToRem(390)};

          &:nth-of-type(2) {
            -ms-grid-column: 3;
            grid-column: 3;
          }

          &.isMessageField {
            max-width: none;

            ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
              -ms-grid-column: span 3;
              grid-column: span 3;
            }

            textarea {
              min-height: 8em;
            }
          }
        }

        .mktoHasWidth {
          width: initial !important;
        }

        .mktoForm {

          .mktoRequiredField .mktoAsterix,
          .mktoField,
          .mktoFieldWrap,
          .mktoLabel {
            float: none;
          }

          .mktoLabel {
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0.625em;
            padding: 0;
            width: 100% !important;
          }

          .mktoRequiredField {

            .mktoAsterix {
              color: ${COLORS.tertiaryA11y};
              margin-left: 0.2em;
              order: 99;
              padding: 0;
            }
          }

          .mktoField {
            appearance: none;
            background: ${COLORS.lightest};
            border: 1px solid ${hexToRgba(COLORS.mid, 0.5)};
            border-radius: 0.2em;
            box-shadow: none;
            color: ${COLORS.darkest};
            font-size: 1rem;
            line-height: 1.2em;
            height: auto;
            padding: 0.75em 1em;
            transition: border-color 250ms ease-in-out;
            width: 100% !important; // WHY: Overridden as Marketo forces a width in the element’s <style>

            &:focus {
              border-color: ${COLORS.primary};
            }

            &.mktoInvalid {
              border-color: ${COLORS.tertiary};
            }

            &.isDropdown {
              background-image: url(${IconSelectDropdownSvg});
              background-position: center right;
              background-repeat: no-repeat;
              padding-right: calc(0.5em + 2.8125rem);
            }

            &::placeholder,
            &.isPlaceholder {
              color: ${hexToRgba(COLORS.mid, 0.75)};
            }

            &[disabled] {
              opacity: 0.33;
            }
          }

          .mktoError {

            .mktoErrorArrow,
            .mktoErrorMsg {
              background: ${COLORS.tertiaryA11y};
              border: 0;
              box-shadow: none;
              color: ${COLORS.lightest};
              text-shadow: none;
            }
          }
        }

        .mktoButton {
          // WHY: !important because Marketo lost the specificty war
          ${responsiveFontSizes(fontSizeSmall, fontSizeLarge, { important: true })}

          background-color: none !important;
          background-repeat: no-repeat !important;
          background-image: linear-gradient(${COLORS.secondaryA11y}, ${COLORS.secondaryA11y}) !important;
          background-size: calc(100% - ${hoverTransform}) 100% !important;
          border: 0 !important;
          box-shadow: none !important;
          color: ${COLORS.lightest} !important;
          padding: 1.2em 1.5em !important; // TODO: Replace with values from CallToAction
          padding-right: calc(1.5em + ${hoverTransform}) !important; // WHY: Allows the space from
          position: relative;
          transition: background ${TRANSITIONS.regular}, background-color ${TRANSITIONS.regular};

          &[type="submit"] {

            &::after {
              background: url(${CtaArrowSvg}) center no-repeat;
              content: '';
              display: inline-block;
              margin-left: 2em;
              // WHY: Sizes come from the SVG
              width: ${(29 / fontSizeLarge).toFixed(3)}em;
              height: ${(18 / fontSizeLarge).toFixed(3)}em;
              transition: transform ${TRANSITIONS.regular};
              vertical-align: top;
            }
          }

          :hover,
          :focus,
          :active {
            background-image: linear-gradient(${COLORS.secondaryA11yDark}, ${COLORS.secondaryA11yDark}) !important;
            background-size: 100% 100% !important;

            ::after {
              transform: translateX(calc(${hoverTransform} / 2));
            }
          }

          :active {
            background-image: linear-gradient(${COLORS.secondaryA11yDarker}, ${COLORS.secondaryA11yDarker}) !important;
          }

          :focus:not(:active) {
            box-shadow: ${COLORS.primary} 0 0 ${pixelsToRem(5)} !important;
          }
        }
      `}
    >
      {/* WHY: As successHeading (and successMessage respectively) falls back to heading, we can check for just the
               presence of the successHeading rather than having a more complicated ternary operator */}
      {successHeading &&
        <Heading tag="h2" spacingBottom="half" spacingTop="none">
          {formSuccess ? successHeading : heading}
        </Heading>
      }

      {successMessage &&
        <Paragraph>
          {formSuccess ? successMessage : message}
        </Paragraph>
      }

      <div
        ref={formWrapper}
        css={css`
          overflow: hidden;
          height: auto;
          transition: height 250ms ease-in-out;
        `}
      >
        <form id={`mktoForm_${formId}`}/>
      </div>
    </section>
  )
};

export default MarketoForm;
