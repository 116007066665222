/**
 * Uses the smoothScrollToElement function to scroll to an ID
 *
 * @param {String} id - ID of the element (# is removed if left in)
 * @param {Number} offset - pixels that are to be added to the scroll
 */
export const smoothScrollToId = (id, offset = 0) => {
  if (id.startsWith(`#`)) {
    id = id.slice(1);
  }

  const element = document.getElementById(id);

  smoothScrollToElement(element, offset);
};


/**
 * Smoothly scrolls the page to a specified element
 *
 * @param {HTMLElement} element - the element to be scrolled to
 * @param {Number} offset – pixels that are to be added to the scroll
 */
export const smoothScrollToElement = (element, offset = 0) => {
  if (!element) return;

  const headerOffset = document.getElementById('site-header').clientHeight;
  const top = (window.scrollY || window.pageYOffset) + (element.getBoundingClientRect().top) + offset - (headerOffset * 1.5);

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top,
      behavior: `smooth`,
    });
  }

  else {
    window.scrollTo(0, top);
  }
};
