import React from "react";
import { css } from "@emotion/core";

/**
 * A reusable component for instances where aria-label is insufficient to pass the Wave tool.
 * It borrows the styles from the widely-used .visuallyhidden we use on fueled.com (that was
 * originally from the HTML5 bootstrap).
 */
export const HiddenTextForScreenReader = ({ children, ...props }) => {
  return (<span {...props} css={css`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `}>{children}</span>)
};
